<script lang="ts">
	import { createEventDispatcher } from 'svelte';

	const dispatch = createEventDispatcher();

	// Required since modals can be opened via
	// const modal = document.getElementById('<the-id-as-string>')! as HTMLDialogElement;
	// modal.showModal();
	export let id: string;

	// close on click outside
	export let clickOutside = true;

	export let closeButton = true;

	let modal: HTMLDialogElement;
</script>

<slot name="button" open={modal.showModal} for={id} />

<dialog bind:this={modal} {id} class="modal modal-bottom sm:modal-middle">
	<div class="modal-box {$$props.class || ''}">
		{#if closeButton}
			<form method="dialog" on:submit={() => dispatch('close-button')}>
				<button
					class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
					aria-label="close modal"
				>
					✕
				</button>
			</form>
		{/if}

		<slot />

		{#if 'actions' in $$slots}
			<div class="modal-action">
				<form method="dialog" class="items-center gap-2">
					<slot name="actions" />
				</form>
			</div>
		{/if}
	</div>

	{#if clickOutside}
		<!-- Do not change this -->
		<form method="dialog" on:submit={() => dispatch('backdrop')} class="modal-backdrop">
			<button>Close</button>
		</form>
	{/if}
</dialog>
