<script lang="ts">
	import DebugScreens from '$internal/components/dev/DebugScreens.svelte';
	import ModalPrivacyCustomize from '$internal/components/modal/ModalPrivacyCustomize.svelte';
	import Seo from '$internal/components/seo/Seo.svelte';
	import ThemeInlineScript from '$internal/components/theme/ThemeInlineScript.svelte';
	import ToastPrivacy from '$internal/components/toast/ToastPrivacy.svelte';
	import Layout from '$internal/components/layout/Layout.svelte';
	import '../app.css';

	export let data;
</script>

<Seo />
<ThemeInlineScript />
<DebugScreens />
<ModalPrivacyCustomize />

<Layout pathname={data.pathname}>
	<slot />
</Layout>

<div class="toast z-50">
	<ToastPrivacy />
</div>
