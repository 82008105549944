/**
 * Generates an inline JavaScript script that handles theme switching based on user preferences
 * and stores the selected theme in localStorage.
 * 
 * 1. get store theme from localStorage
 * 2. if not found use one of the fallbacks based on prefers-color-scheme
 * 3. apply the theme
 * 4. listen for settings change
 *
 * @param {readonly string[]} themes - An array of available themes.
 * @param {string} daisiuiKey - A key used to set an attribute on the HTML root element for theme tracking.
 * @param {string} storeKey - A key used to store the selected theme in the browser's localStorage.
 * @param {string} fallbackLightKey - The default theme key when the user has no preference or prefers light mode.
 * @param {string} fallbackDarkKey - The default theme key when the user prefers dark mode.
 * @returns {string} - The generated inline JavaScript script.
 * 
 */
export function inlineScript(
	daisiuiKey: string,
	storeKey: string,
	fallbackLightKey: string,
	fallbackDarkKey: string
): string {
	return `<script>
{
	const s = localStorage;
	const h = document.documentElement;
	let m = matchMedia('(prefers-color-scheme: dark)');
	const d = (v) => { h.setAttribute("${daisiuiKey}", v); s.setItem("${storeKey}", v); };
	let v = s.getItem("${storeKey}");
	if(!v) v =  m.matches ? "${fallbackDarkKey}" : "${fallbackLightKey}";
	d(v);
	m.addEventListener("change", ({ matches }) => d(matches ? "${fallbackDarkKey}" : "${fallbackLightKey}"))
}
</script>`;
}
