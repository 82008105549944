<script lang="ts">
	import { fade } from 'svelte/transition';
	import { modals } from '$const/ids';
	import { onMount } from 'svelte';
	import { privacyPreferences } from '$const/cookies';
	import { showModal } from '$internal/utils/modal';
	import type { PrivacyPreferences } from '$routes/api/privacy/types';

	let show = false;
	onMount(() => {
		const stored = localStorage.getItem(privacyPreferences.name);
		if (!stored) {
			show = true;
			return;
		}
	});

	function post(accepted: boolean, preferences: PrivacyPreferences) {
		localStorage.setItem(
			privacyPreferences.name,
			JSON.stringify({ accepted, ...preferences, updatedOn: new Date().toISOString() })
		);
		show = false;
	}

	async function customize(): Promise<void> {
		show = false;
		showModal(modals.privacyConsentCustomize);
	}
</script>

{#if show}
	<output transition:fade class="alert p-0 card card-compact card-bordered shadow-2xl">
		<div class="card-body max-w-md whitespace-normal">
			<h2 class="card-title">Cookie consent</h2>
			<p>
				This website uses
				<a href="/privacy/cookies#cookie-policy" class="link"> cookies </a>
				to enhance the user experience.
			</p>
			<p>Read our <a href="/privacy/policy" class="link"> privacy policy </a> for more info.</p>

			<div class="mt-4 card-actions">
				<button
					on:click={() => post(true, { strictly: true, analytical: true })}
					class="btn btn-primary normal-case"
				>
					Accept
				</button>
				<button
					on:click={() => post(false, { strictly: true, analytical: false })}
					class="btn ml-auto normal-case"
				>
					Opt-out
				</button>
				<button on:click={customize} class="btn normal-case"> Customize </button>
			</div>
		</div>
	</output>
{/if}
