<script lang="ts">
	import { privacyPreferences } from '$const/cookies';
	import { modals } from '$const/ids';
	import type { PrivacyPreferences } from '$routes/api/privacy/types';
	import Modal from './Modal.svelte';

	let preferences: PrivacyPreferences = {
		strictly: true,
		analytical: false
	};

	async function post(accepted: boolean, preferences: PrivacyPreferences) {
		localStorage.setItem(
			privacyPreferences.name,
			JSON.stringify({ accepted, ...preferences, updatedOn: new Date().toISOString() })
		);
	}
</script>

<Modal
	id={modals.privacyConsentCustomize}
	on:close-button={() => post(true, { strictly: true, analytical: false })}
	on:backdrop={() => post(true, { strictly: true, analytical: false })}
>
	<fieldset class="flex flex-col gap-4">
		<legend class="text-3xl font-semibold mb-8">Cookie Consent</legend>

		<div class="form-control">
			<label class="label gap-2 justify-start items-center cursor-not-allowed">
				<input type="checkbox" disabled bind:checked={preferences.strictly} class="checkbox" />
				<span class="label-text font-semibold">Strictly necessary cookies</span>
			</label>
			<p>
				These are cookies that are required for the operation of our Site and under our terms with
				you. They include, for example, cookies that enable you to log into secure areas of our Site
				or (on other sites) use a shopping cart or make use of e-billing services.
			</p>
		</div>

		<div class="form-control">
			<label class="label gap-2 justify-start items-center cursor-pointer">
				<input type="checkbox" bind:checked={preferences.analytical} class="checkbox" />
				<span class="label-text font-semibold">Analytical cookies</span>
			</label>
			<p>
				These allow us to recognize and count the number of visitors and to see how visitors move
				around our site when they are using it. This helps us improve the way our Website works, for
				example, by ensuring that users are finding what they are looking for easily.
			</p>
		</div>
	</fieldset>

	<p class="mt-4 text-sm">
		For more information on cookies, please visit our
		<a href="/privacy/policy" class="link"> privacy policy </a>
		page.
	</p>

	<div slot="actions">
		<button
			on:click={() => post(true, { strictly: true, analytical: false })}
			class="btn btn-ghost normal-case"
		>
			Cancel
		</button>
		<button on:click={() => post(true, preferences)} class="btn normal-case"> Confirm </button>
	</div>
</Modal>
