<script lang="ts">
	import { fallbackDarkKey, fallbackLightKey, localhostKey, themes } from '$const/theme';
	import { DAISIUI_KEY, theme } from '$internal/stores/theme';
	import { inlineScript } from './themeInlineScript';

	if (!themes.includes(fallbackLightKey))
		console.error(`The fallbackLightKey "${fallbackLightKey}" is not registered in themes`);
	else if (!themes.includes(fallbackDarkKey))
		console.error(`The fallbackDarkKey "${fallbackDarkKey}" is not registered in themes`);

	theme.init()
</script>

<svelte:head>
	{@html inlineScript(DAISIUI_KEY, localhostKey, fallbackLightKey, fallbackDarkKey)}
</svelte:head>
