<script lang="ts">
	import { page } from '$app/stores';
	import * as website from "$const/website"

	let seo: App.PageData['seo'];

	let ok = false;
	page.subscribe(($page) => {
		if ($page.url.pathname.startsWith('/api')) return;

		if (!$page.data.seo) {
			ok = false;
			console.error(`${$page.url} needs SEO setup.`);
			return;
		}
		ok = true;
		seo = $page.data.seo;
	});
</script>

<svelte:head>
	{#if ok}
		<title>{seo.title}</title>
		<meta name="description" content={seo.description} />
		<meta
			name="robots"
			content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
		/>
		<link rel="canonical" href={seo.canonicalUrl} />
		<meta name="keywords" content={seo.keywords.join(', ')} />

		<meta property="og:type" content="website" />
		<meta property="og:site_name" content={website.title}>
		<meta property="og:title" content={seo.og.title} />
		<meta property="og:description" content={seo.og.description} />
		<meta property="og:image" content={seo.og.image} />
		<meta property="og:url" content={seo.og.url} />

		<meta name="twitter:card" content={seo.twitter.card} />
		<meta name="twitter:title" content={seo.twitter.title} />
		<meta name="twitter:description" content={seo.twitter.description} />
		<meta name="twitter:image" content={seo.twitter.image} />
		<meta name="twitter:site" content={seo.twitter.site} />
	{/if}
</svelte:head>
